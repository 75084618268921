.Parallax {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200%;
  display: flex;
  justify-content: center;
  z-index: var(--z-bottom);
  overflow: hidden;
}

.Parallax__background {
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
}

.Parallax__background::before{
  content: "";
  position: absolute;
  bottom: -2em;
  left: 0;
  width: 100%;
  height: 120%;
  background: linear-gradient(transparent, hsl(var(--c-background)));
}

.Parallax__canvas {
  min-width: 100%;
  min-height: 100%;
  transform: translate3d(0,0,-10%);
  -webkit-transform: translate3d(0,0,-10%);
  -moz-transform: translate3d(0,0,-10%);
  -ms-transform: translate3d(0,0,-10%);
  -o-transform: translate3d(0,0,-10%);
}

