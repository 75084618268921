.hidden {
  display: none !important;
}

.invisible {
  opacity: 0;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}

.show {
  animation: animShow 0.8s ease-out;
  -webkit-animation: animShow 0.8s ease-out;
}

@keyframes  animShow {
    0% {
      opacity: 0;
      transform: translate(0,5em);
      -webkit-transform: translate(0,5em);
      -moz-transform: translate(0,5em);
      -ms-transform: translate(0,5em);
      -o-transform: translate(0,5em);
    }
    100% {
      opacity: 1;
      transform: translate(0,0);
      -webkit-transform: translate(0,0);
      -moz-transform: translate(0,0);
      -ms-transform: translate(0,0);
      -o-transform: translate(0,0);
    }
}
