.swiper-cards {
  padding: 0 5em !important;
}

.swiper-slide-shadow {
  background: transparent !important;
}
.swiper-cards .swiper-slide {
   overflow:visible !important;
}
