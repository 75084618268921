.section {
  padding: clamp(3rem,10vw,5em) 0;
}

.container {
  max-width: 115em;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
}
