.nav {
  font-size: 1.1rem;
}

.nav__list {
  column-gap: 1.5em;
}

.nav__item {
  padding: .2em 0;
  position: relative;
  cursor: pointer;
  transition: all var(--t-slow) ease-in-out;
  -webkit-transition: all var(--t-slow) ease-in-out;
  -moz-transition: all var(--t-slow) ease-in-out;
  -ms-transition: all var(--t-slow) ease-in-out;
  -o-transition: all var(--t-slow) ease-in-out;
}

.nav__item::after {
  content: " ";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: hsl(var(--c-primary));
  transition: 0.3s;
}

.nav__item:hover:after {
  width: 100%;
  box-shadow: 0px 1px 10px 0px hsla(var(--c-brand-200),0.9);
}

.burger-menu {
  min-height: 100vh;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  width: 100dvw;
  position: absolute;
  display: flex;
  justify-content: end;
  top: 0;
  right: -120%;
  z-index: var(--z-top-bot);
  color: hsl(var(--c-ui-light));
  --dropdown-col:  hsl(var(--c-ui-light));
  background-color: hsla(var(--c-secondary),0.1);
  -webkit-backdrop-filter: blur(0.4em);
          backdrop-filter: blur(0.4em);
  transition: right var(--t-middle) ease-in-out;
}

.burger-menu--activ {
  right: 0;
}

.burger-menu__list {
  flex-direction: column;
  padding: 6em 7em 0 3em;
  background-color: hsl(var(--c-background));
}

.burger-menu__special-element:last-child {
  margin-top: auto;
  font-weight: 400;
}
/* //// */

.burger {
  --burger-width: 2em;
  --burger-height: 1.8em;
  margin-bottom: 4px;
  display: block;
  position: relative;
  width: var(--burger-width);
  height: var(--burger-height);
  padding: 0;
  z-index: var(--z-top-mid);
  background: none;
  border: none;
  cursor: pointer;
  color: inherit;
}

.burger__text {
  margin-left: 1em;
  padding: 0.4em 0;
  color: inherit;
  font-family: inherit;
}

.burger .burger__span,
.burger::before,
.burger::after {
  --burger-line-size: 3px;
  content: "";
  height: var(--burger-line-size);
  position: absolute;
  background-color: hsl(var(--c-ui-light));
  transition: all var(--t-slow) ease;
}

.burger--left .burger__span,
.burger--left::before,
.burger--left::after  {
  left: 0;
}

.burger--right .burger__span,
.burger--right::before,
.burger--right::after  {
  right: 0;
}

.burger:hover .burger__span,
.burger:hover::before,
.burger:hover::after{
  width: 100%;
  background-color: hsl(var(--c-ui-light));
}

.burger::before {
  top: 0;
  width: 100%;
}

.burger .burger__span {
  top: calc(var(--burger-height) / 2 - var(--burger-line-size) / 2);
  width: 60%;
}

.burger::after {
  bottom: 0;
  width: 40%;
}

.burger--active:before {
  transform: rotate(45deg);
  top:  calc(var(--burger-height) / 2 - var(--burger-line-size));
  width: 100%;
}

.burger--active:after {
  transform: rotate(-45deg);
  bottom: calc(var(--burger-height) / 2);
  width: 100%;
}

.burger--active .burger__span {
  transform: scale(0);
}

@media (min-width: 64em) {
  .burger {
    display: none;
  }
}
