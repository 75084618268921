.header {
  position: fixed;
  width: 100%;
  z-index: var(--z-top-max);
  background: hsla(var(--c-background),.5);
  transform: translateY(0);
  transition: all var(--t-slow) ease-in-out;
}

.header[state='true'] {
  backdrop-filter: blur(0.6em);
}

.header_hidden {
  transform: translateY(-100%);
}

.header__container {
  padding: 1em 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}
@media (min-width: 40em) {
  .header__container {
    align-items: center;
  }
}
[data-theme='light']
.logo {
  color: hsl(var(--c-primary));
}

.logo__link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  -moz-column-gap: clamp(0em,1vw,1em);
       column-gap: clamp(0em,1vw,1em);
}

.logo__icon {
  width: 2em;
  height: clamp(3.5em,8vw,4.5em);
}

.logo__description {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  text-transform: uppercase;
}

.logo__description--osu {

}

.logo__text {
  font-size: clamp(.4rem,3vw,0.8rem);
  margin: 0;
  padding: 0;
  color: inherit;
}

.logo__text--strong {
  font-size: clamp(1rem,4vw,1.8rem);
}

