.heading {
  margin: 0;
  margin-bottom: 1.5em;
  padding: 0;
  font-size: 1.5rem;
  font-weight: 400;
}

.heading--accent {
  color: hsl(var(--c-text-brand));
}

.heading--strong {
  font-weight: 700;
}

.heading--main {
  font-size: 1.8rem;
  font-weight: 800;
}

.heading--secondery {
  margin: 0;
  font-size: 1.2rem;
}


@media (min-width: 43em) {
  .heading {
      font-size: 2.3rem;
  }

  .heading--main {
      font-size: 2.5rem;
  }

  .heading--secondery {
      font-size: 1.4rem;
  }
}
