html {
  /* color */

    /* color */

  --c-brand-200: 214, 93%, 83%;
  --c-brand-300: 214, 93%, 73%;
  --c-brand-400: 214, 81%, 60%;
  --c-brand-500: 214, 84%, 52%;
  --c-brand-600: 214, 90%, 37%;
  --c-brand-700: 214, 94%, 30%;
  --c-brand-800: 214, 98%, 27%;
  --c-brand-900: 214, 100%, 8%;

  --c-secondary-000: 181, 91%, 62%;

  --c-neutral-100: 228, 100%, 100%;
  --c-neutral-200: 228, 13%, 92%;
  --c-neutral-300: 228, 17%, 83%;
  --c-neutral-400: 227, 12%, 71%;
  --c-neutral-500: 212, 7%, 49%;
  --c-neutral-600: 208, 9%, 32%;
  --c-neutral-700: 207, 11%, 20%;
  --c-neutral-800: 210, 13%, 15%;
  --c-neutral-900: 228, 50%, 0%;

  /* z-index */
  --z-top-max:  400;
  --z-top-mid:  300;
  --z-top-bot:  200;
  --z-mid:      100;
  --z-bottom:  -100;

  /* time */
  --t-slow:   .3s;
  --t-middle: .2s;
  --t-fast:   .1s;
  /*  */
}
/* map tokens to proper theme */
[data-theme='light'] {
  --c-primary:    var(--c-brand-500);
  --c-secondary:  var(--c-secondary);
  --c-background: var(--c-brand-900);

  --c-ui-light:   var(--c-neutral-100);
  --c-ui:         var(--c-neutral-200);
  --c-ui-dark:    var(--c-neutral-300);

  --c-text:        var(--c-neutral-700);
  --c-text-accent: var(--c-neutral-100);
}

[data-theme='dark'] {
  --c-primary:    var(--c-brand-500);
  --c-secondary:  var(--c-secondary-000);
  --c-background: var(--c-brand-900);

  --c-ui-light:   var(--c-neutral-100);
  --c-ui:         var(--c-neutral-200);
  --c-ui-dark:    var(--c-neutral-300);

  --c-text:        var(--c-neutral-100);
  --c-text-accent: var(--c-neutral-700);
  --c-text-brand:  var(--c-brand-500);
}

*,
::before,
::after {
  box-sizing: border-box;
  scroll-behavior: smooth;
  text-wrap: balance;
}

body {
  font-family: sans-serif;
  font-weight: 100;
  color: hsl(var(--c-text));
  background-color: hsl(var(--c-background));
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  display: block;
  max-width: 100%;
}

samp {
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
}
