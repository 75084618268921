.list-reset {
  margin: 0;
  padding: 0;
  list-style: none;
  flex-direction: column;
  display: flex;
  row-gap: .5em;
  -moz-column-gap: .5em;
       column-gap: .5em;
}

.list-row {
  flex-direction: row;
}
