.footer {
  padding: 3em 0;
}

.footer__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.footer__contacts {
  padding-top: 1em;
  padding-left: 3em;
}
